import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

export const fetchUserDetail = createAsyncThunk(
  'user-detail/fetchStatus',
  async (username: string, thunkAPI) => {
    try {
      const response = await apiClient.get('/user/get-user-detail/' + username);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchUserDetailById = createAsyncThunk(
  'user-detail-by-id/fetchStatus',
  async (id: string, thunkAPI) => {
    try {
      const response = await apiClient.get('/user/get-user-detail-by-id/' + id);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  'user-profile/fetchProfileStatus',
  async (id: string, thunkAPI) => {
    try {
      const response = await apiClient.get('/user/profile/' + id);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

interface linkAccount {
  account_address: string;
  account_address_wallet_type: string;
}
export const linkUserAccount = createAsyncThunk(
  'linkUserAccount/linkUserAccountStatus',
  async (formData: linkAccount, thunkAPI: any) => {
    try {
      const userDetail = JSON.parse(localStorage.getItem('authGcUser') || '{}');
      const response = await apiClient.put('/user/' + userDetail.id, formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);


interface verifyInvitationKey {
  invitationKey: string;
}
export const verifyInvitationKey = createAsyncThunk(
  'verify-invitation-key/verifyInvitationKeyStatus',
  async (formData: verifyInvitationKey, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/user/check-invitation-key', formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialUserState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
  users: {},
  user: {
    _id: null,
    name: null,
    invitation_code: null,
    invitation_key: null,
  },
  profile: {
    profile: {
      _id: null,
      name: null,
      invitation_code: null,
      invitation_key: null,
      account_address: null,
      account_address_wallet_type: null,
    },
    unionMemberDetail: {
      _id: null,
      createed_at: null,
      email: null,
      member_id: null,
      name: null,
      phone: null,
      updated_at: null,
      user_id: null,
      verified: null
    }
  },
};

const userSlice = createSlice({
  name: 'apis',
  initialState: initialUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetail.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.user = action.payload.data;
      })
      .addCase(fetchUserDetail.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      })
      .addCase(fetchUserDetailById.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchUserDetailById.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.user = action.payload.data;
      })
      .addCase(fetchUserDetailById.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      })

      .addCase(fetchUserProfile.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.profile = action.payload.data;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      })

      .addCase(linkUserAccount.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(linkUserAccount.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(linkUserAccount.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      })

      .addCase(verifyInvitationKey.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(verifyInvitationKey.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(verifyInvitationKey.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      })

      ;
  }
});

export default userSlice.reducer;
