import { Header } from 'antd/lib/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import Logo from '../../assets/images/logo-75.png';
import './auth-header.less';

interface Props {
  languages: any;
  userName: String;
}
const AuthHeader: React.FC<Props> = ({ languages, userName }) => {
  const navigate = useNavigate();
  const logout = () => {
    console.log('logout');
    window.localStorage.setItem("provider", 'undefined');
    window.localStorage.removeItem("authGcUser");
    return navigate('/', { replace: true });
  }

  return (
    <Header className='auth-header'>
      <div className='logo logo-sm'>
        <Link to="/"><img src={Logo} alt={languages.app.title} /></Link>
      </div>

      <div className='auth-header-menu'>
        <Menu mode="horizontal" className='auth-header-usermenu' defaultSelectedKeys={['user']}>
          <Menu.SubMenu key="user" title={userName} icon={<SettingOutlined />}>
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>

    </Header>

  )
}

export default AuthHeader;