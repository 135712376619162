import { useState } from "react";
const useHandleFocus = () => {
    const [focusRef, setFocusRef] = useState<any>();
    let refId = focusRef?.current?.props?.id
    document.getElementById(refId)?.focus()
    const handleFocusFullNameBox = (e: any) => {
        focusRef?.current?.focus();
    }
    return { handleFocusFullNameBox, setFocusRef }
}

export default useHandleFocus;