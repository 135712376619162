import { unwrapResult } from '@reduxjs/toolkit';
import { Alert, Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import logo from '../../../assets/images/logo-lg.png';
import FooterLink from '../../../components/common/footer-link';
import BlankLayoutComponent from '../../../components/layout/BlankLayoutComponent';
import useService from '../../../hooks/service.hooks';
import { register } from '../../../stores/auth/register.slice';
import { useAppDispatch } from '../../../stores/store';
import { verifyInvitationKey } from '../../../stores/users/users.slice';
import './register.less';


const { Title, Text } = Typography;
const { Option } = Select;
interface Props {
  languages: any;
}
const RegisterFeature: React.FC<Props> = ({ languages }) => {
  const meta = {
    title: languages.head.title_join_now,
    description: '',
    keyword: '',
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [serviceList, setServiceList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [invitorKey, setInvitorKey] = useState('');
  const [showFields, setShowFields] = useState(false);

  const { services } = useService();

  useEffect(() => {
    if (localStorage.getItem('authGcUser')) {
      console.log('logged');
      navigate('/dashboard', { replace: true });
    }
    if (services) {
      setServiceList([]);
      Object.values(services).map((service: any) => {
        const _services = {
          key: service._id,
          name: service.name,
          status: service.status ? 'Yes' : 'No',
          creatrd_by: service.created_by,
          mobility: service.mobility ? 'Yes' : 'No',
        }
        setServiceList((prevData: any) => [_services, ...prevData]);
      });
    };
  }, [services]);

  const onFinish = (values: any) => {
    console.log({ values });
    if (values.firstName !== null && values.firstName !== null && values.email !== null && values.serviceId !== null && values.mobile !== null && values.inviterKey !== null) {
      setIsLoading(true);
      const registrationData = {
        name: values.firstName + " " + values.lastName,
        email: values.email,
        phone: values.mobile,
        service_id: values.serviceId,
        account_address: uuid(),
        country_code: values.country_code,
        device_token: uuid(),
        inviter_key: values.inviterKey
      };
      console.log({ registrationData });
      dispatch(register(registrationData)).then(unwrapResult).then(res => {
        if (res.success) {
          setIsLoading(false);
          setSuccess(res.data.success);
          navigate('/auth/login');
        }
      })
        .catch(rejected => {
          setIsLoading(false);
          setSuccess(rejected.data.success);
          setMessage(rejected.data.message);
        });

    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const preventDecimal = (e: any) => {
    if (e.key === '.') { e.preventDefault(); }
  }

  const countryCodeSelector = (
    <Form.Item name="country_code" noStyle rules={[
      {
        required: true,
        message: 'Please select a country code.',
      },
    ]}>
      <Select style={{ width: 120 }} placeholder="Country Code">
        <Option key={1} value="+1">+1</Option>
      </Select>
    </Form.Item>
  );

  const verifyKey = () => {
    console.log(invitorKey);
    if (invitorKey != '') {
      setIsLoading(true);
      const data = {
        invitationKey: invitorKey
      }
      dispatch(verifyInvitationKey(data)).then(unwrapResult).then(res => {
        if (res.success) {
          setIsLoading(false);
          setShowFields(true);
          setSuccess(res.success);
          setMessage(res.message);
        }
      })
        .catch(rejected => {
          setIsLoading(false);
          setSuccess(rejected.data.success);
          setMessage(rejected.data.message);
        });

    }
    setIsLoading(false);
  }

  return (
    <BlankLayoutComponent languages={languages} meta={meta} displayNavbar={false} displayFooter={false}>
      <div className='register-feature'>
        <div className='register-feature-logo'>
          <Link to="/"><img src={logo} alt="GigCity" /></Link>
        </div>

        <Card bordered={false} className='register-feature-card'>
          <Title level={3} className="card-title">{languages.heading.text_join_now}</Title>
          <Form
            layout="vertical"
            className="gig-register-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            {message !== '' ? <><Alert
              message={success ? 'SUCCESS' : 'ERROR'}
              description={message}
              type={success ? 'success' : 'error'}
            /> <br /> </> : ''}

            <Row gutter={24}>
              {!showFields
                ? <>
                  <Col span={18}>
                    <Form.Item
                      name="inviterKey"
                      label="Invitation Key"
                      tooltip="Get your invitation key."
                      rules={[{ required: true, message: 'Please please provide invitation key!' }]}
                    >
                      <Input type="text" placeholder="Enter your invitation key." size="large" className="text-field" value={invitorKey || ''}
                        onChange={(e) => setInvitorKey(e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label='&nbsp;'>
                      <Button loading={isLoading}
                        type="primary"
                        size="large"
                        onClick={() => verifyKey()}
                        htmlType="button">Verify</Button>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    Don't have an invitation key? 
                    <Button type="link" href="guest-request/key">{languages.link.text_get_invtation_key}</Button>
                  </Col>
                </>
                : <Col span={6}>Invitation Key<Title level={5}>{invitorKey}</Title></Col>}
            </Row>


            {showFields
              ? <>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your first name.',
                    },
                  ]}
                >
                  <Input type="text" placeholder="Enter your first name" size="large" className="text-field" />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true, message: 'Please enter your last name.', },]}
                >
                  <Input type="text" placeholder="Enter your last name" size="large" className="text-field" />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email address.',
                    },
                  ]}

                >
                  <Input
                    type="email"
                    placeholder="Enter an email."
                    size="large"
                    className="text-field"

                  />
                </Form.Item>

                <Form.Item
                  name="serviceId"
                  label="Service"
                  rules={[{ required: true, message: 'Please select a service.', },]}
                >
                  <Select
                    placeholder="Driver for"
                    allowClear
                  >
                    {serviceList.map((service: any) =>
                      <Option key={service.key} value={service.key}>{service.name}</Option>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="mobile"
                  label="Mobile Number"
                  rules={[{ required: true, message: 'Please input your mobile number!' }]}
                >
                  <Input
                    addonBefore={countryCodeSelector}
                    style={{ width: '100%' }} className="text-field"
                    placeholder="Enter your mobile number."
                  />
                </Form.Item>

                <Button
                  loading={isLoading}
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  className="center-btn"
                  block
                >
                  {languages.button.text_join_now}
                </Button>

                <p><br />By clicking Join Now, you accept Privacy Policy &amp; Terms and Conditions.</p>
              </>
              : ''}
          </Form>
        </Card>

        <div className='gig-account-note'>
          {languages.general.text_have_account}
          <Button

            type="default"
            shape="round"
            size="large"
            htmlType="submit"
            className="center-btn"
            block
          >

            <Link style={{ color: 'black' }} to="/auth/login">{languages.button.text_login}</Link>
          </Button>


        </div>

        < FooterLink />
        <div className="bottom-img-absolute">
          <img
            src="../assets/img/hero-bg-shape-1.svg"
            alt="wave"
            width="120"
            className="img-fluid mb-3"
          />
        </div>
      </div>

    </BlankLayoutComponent >
  );
}

export default RegisterFeature;