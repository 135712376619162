import { unwrapResult } from '@reduxjs/toolkit';
import { Alert, Button, Card, Form, Input, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/logo-lg.png';
import FooterLink from '../../components/common/footer-link';
import BlankLayoutComponent from '../../components/layout/BlankLayoutComponent';
import { sendGuestRequest } from '../../stores/guest-request/guest-request.slice';
import { useAppDispatch } from '../../stores/store';
import './guest-request.less';


const { Title, Text } = Typography;
const { Option } = Select;

interface Props {
  languages: any;
}

const GuestRequestFeature: React.FC<Props> = ({ languages }) => {
  const param = useParams();
  const meta = {
    title: languages.head.title_guest_request,
    description: '',
    keyword: '',
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState('');
  const [requestType, setRequestType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [guestRequestFormState] = Form.useForm();

  useEffect(() => {
    if (param.type == 'key') {
      setTitle(languages.heading.title_guest_invitation_request);
      setRequestType('invitation');
    } else {
      setTitle(languages.heading.title_guest_request);
      setRequestType('genearl');
    }
    guestRequestFormState.setFieldsValue({
      requestType: requestType,
      message: "I need an invitation key to join GigCity.",
    });
  }, [param.type, title]);

  const onFinish = (values: any) => {
    console.log({ values });
    if (values.firstName !== null && values.lastName !== null && values.email !== null && values.mobile !== null && values.requestType !== null && values.message !== null) {
      setIsLoading(true);
      const requestData = {
        name: values.firstName + " " + values.lastName,
        email: values.email,
        phone: values.country_code + values.mobile,
        request_type: values.requestType,
        message: values.message,
      };
      console.log({ requestData });
      dispatch(sendGuestRequest(requestData)).then(unwrapResult).then(res => {
        if (res.success) {
          setIsLoading(false);
          setSuccess(res.success);
          setMessage(res.message);
        }
      })
        .catch(rejected => {
          setIsLoading(false);
          setSuccess(rejected.data.success);
          setMessage(rejected.data.message);
        });

    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const countryCodeSelector = (
    <Form.Item name="country_code" noStyle rules={[
      {
        required: true,
        message: 'Please select a country code.',
      },
    ]}>
      <Select style={{ width: 120 }} placeholder="Country Code">
        <Option key={1} value="+1">+1</Option>
      </Select>
    </Form.Item>
  );

  return (
    <BlankLayoutComponent languages={languages} meta={meta} displayNavbar={false} displayFooter={false} >
      <div className='guest-request-feature'>
        <div className='guest-request-feature-logo'>
          <Link to="/"><img src={logo} alt="GigCity" /></Link>
        </div>

        <Card bordered={false} className='register-feature-card'>
          <Title level={3} className="card-title">{title}</Title>

          <Form
            form={guestRequestFormState}
            layout="vertical"
            className="guest-request-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            {message !== '' ? <><Alert
              message={success ? 'Congratulations!' : 'ERROR'}
              description={message}
              type={success ? 'success' : 'error'}
            /> <br /> </> : ''}

            {!success
              ? <>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your first name.',
                    },
                  ]}
                >
                  <Input type="text" placeholder="Enter your first name" size="large" className="text-field" />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="last Name"
                  rules={[{ required: true, message: 'Please enter your last name.', },]}
                >
                  <Input type="text" placeholder="Enter your last name" size="large" className="text-field" />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter your email address.',
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Enter an email."
                    size="large"
                    className="text-field"

                  />
                </Form.Item>

                <Form.Item
                  name="mobile"
                  label="Mobile Number"
                  rules={[{ required: true, message: 'Please input your mobile number!' }]}
                >
                  <Input
                    addonBefore={countryCodeSelector}
                    style={{ width: '100%' }} className="text-field"
                    placeholder="Enter your mobile number."
                  />
                </Form.Item>

                <Form.Item
                  name="message"
                  label="Message"
                  rules={[{ required: true, message: 'Please input message' }]}
                >
                  <Input.TextArea showCount maxLength={100} />
                </Form.Item>

                <Form.Item
                  name="requestType"
                  hidden={true}
                ><Input /></Form.Item>

                <Button
                  loading={isLoading}
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  className="center-btn"
                  block
                >
                  {languages.button.text_send_request}
                </Button>
              </>
              : <>
                <Button
                  type="default"
                  shape="round"
                  size="large"
                  htmlType="button"
                  className="center-btn"
                  block
                >
                  <Link style={{ color: 'black' }} to="/join-now">{languages.button.text_join_now}</Link>
                </Button>
              </>}

          </Form>
        </Card>

        < FooterLink />
      </div>
    </BlankLayoutComponent>);
}

export default GuestRequestFeature;