import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { fetchUserDetailById, fetchUserProfile } from '../stores/users/users.slice';

const useUserDetailById = (id: string) => {
  const dispatch = useDispatch();
  const userDetailzById = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (id !== undefined)
      dispatch(fetchUserDetailById(id));
  }, [id, dispatch]);

  return userDetailzById;
};

export default useUserDetailById;
