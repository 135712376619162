
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

interface registerData {
  name: string,
  email: string,
  phone: string,
  service_id: string,
  account_address: string,
  country_code: string,
}

export const register = createAsyncThunk(
  'register/registerStatus',
  async (formData: registerData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/user', formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialRegisterState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
  user: {},
};

const registerSlice = createSlice({
  name: 'register',
  initialState: initialRegisterState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.user = action.payload.data;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      });
  }
});

export default registerSlice.reducer;