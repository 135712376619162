import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

interface requestData {
  email: string;
}

export const appRequest = createAsyncThunk(
  'apprequest/requestStatus',
  async (formData: requestData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/app/app-request', formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

interface textMeLinkData {
  phone: string;
}
export const appTextMeLink = createAsyncThunk(
  'apptextmelink/requestStatus',
  async (formData: textMeLinkData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/app/text-me-link', formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialAppRequestState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
};

const appRequestSlice = createSlice({
  name: 'appRequest',
  initialState: initialAppRequestState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(appRequest.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(appRequest.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(appRequest.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      })
      .addCase(appTextMeLink.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(appTextMeLink.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(appTextMeLink.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      });
  }
});

export default appRequestSlice.reducer;
