import { unwrapResult } from '@reduxjs/toolkit';
import { Alert, Button, Card, Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo-lg.png';
import FooterLink from '../../../components/common/footer-link';
import BlankLayoutComponent from '../../../components/layout/BlankLayoutComponent';
import { login } from '../../../stores/auth/login.slice';
import { useAppDispatch } from '../../../stores/store';
import './login.less';



const { Title, Text } = Typography;
interface Props {
  languages: any;
}
const LoginFeature: React.FC<Props> = ({ languages }) => {
  const meta = {
    title: languages.head.title_login,
    description: '',
    keyword: '',
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [val, setVal] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('authGcUser')) {
      console.log('logged');
      navigate('/dashboard', { replace: true });
    }
  }, []);

  const onFinish = (values: any) => {

    if (values.mobile !== null) {
      setIsLoading(true);
      const loginData = {
        phone: values.mobile,
      };
      dispatch(login(loginData)).then(unwrapResult).then(res => {
        if (res.success) {
          setIsLoading(false);
          setLoginSuccess(true);
          navigate('/auth/otp', { state: { id: res.data.user._id, mobile: res.data.user.phone } });
        }
      })
        .catch(rejected => {
          setIsLoading(true);
          setLoginSuccess(rejected.data.success);
          setMessage(rejected.data.message);
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const preventDecimal = (e: any) => {
    if (e.key === '.') { e.preventDefault(); }
  }

  return (
    <BlankLayoutComponent languages={languages} meta={meta} displayNavbar={false} displayFooter={false}>
      <div className='login-feature'>
        <div className='login-feature-logo'>
          <Link to="/"><img src={logo} alt="GigCity" /></Link>
        </div>

        <Card bordered={false} className='login-feature-card'>
          <Title level={3} className="card-title">{languages.heading.text_welcome_back}</Title>
          <Form
            layout="vertical"
            className="gig-login-form"
            onFinish={onFinish}
            // onFinishFailed={}
            initialValues={{ remember: true }}
            autoComplete="off"
          >

            {message !== '' ? <Alert
              message="Error"
              description={message}
              type="error"
            /> : ''}

            <Form.Item
              name="mobile"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: 'Please enter your mobile number.',
                },
              ]}

            >
              <Input
                type="tel"
                placeholder="Enter your mobile number"
                size="large"
                className="mobile-field"
                step="1"
                onKeyDown={(event) => preventDecimal(event)}
                inputMode='numeric'
              />
            </Form.Item>

            <Button
              loading={isLoading}
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              className="center-btn"
              block
            >
              {languages.button.text_login}
            </Button>
          </Form>
        </Card>

        <div className='gig-account-note'>
          {languages.general.text_have_no_account}
          <Button
              
              type="default"
              shape="round"
              size="large"
              htmlType="submit"
              className="center-btn"
              block
            >
               <Link style={{color: 'black'}} to="/join-now">{languages.button.text_join_now}</Link>
            </Button>
         
        </div>

        < FooterLink />

        <div className="bottom-img-absolute">
          <img
            src="../assets/img/hero-bg-shape-1.svg"
            alt="wave"
            width="120"
            className="img-fluid mb-3"
          />
        </div>
      </div>

    </BlankLayoutComponent>
  );
}

export default LoginFeature;