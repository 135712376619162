import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores/store';
import { fetchUserDetail } from '../stores/users/users.slice';

const useUserDetail = (username: string) => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (username !== undefined)
      dispatch(fetchUserDetail(username));
  }, [username, dispatch]);

  return userDetail;
};

export default useUserDetail;
