import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Typography, Row, Col } from 'antd';
import QRCode from 'qrcode';

import './profile.less';
import useUserDetailById from '../../hooks/user-detail-by-id.hooks';

const { Text, Title, } = Typography;

const ProfileByIdFeature = () => {
  const param = useParams();
  const [id, setId] = useState<string>();
  const [inviteQrCode, setInviteQrCode] = useState('');
  const { user } = useUserDetailById(id!);
  useEffect(() => {
    setId(param.id);
    if (user) {
      generateQrCode(user._id!, user.invitation_code!);
    }
  }, [param.id, user]);

  const generateQrCode = (userId: string, invitationCode: string) => {
    QRCode.toDataURL(invitationCode).then((setInviteQrCode));
  }
  return (
    <div className='profile-feature'>
      <div className='profile-feature-display'>
        <Divider />
        <div className="download-btn">
          <a href="https://play.google.com/store/apps/details?id=xyz.gigcity.gigcity&hl=en&gl=US" target="_blank" className="btn google-play-btn mr-3 solid-btn">
            <span className="ti-android"></span> Google Play
          </a>
          <a href="https://apps.apple.com/us/app/gigct/id1624812250" target="_blank" className="btn app-store-btn solid-btn">
            <span className="ti-apple"></span> App Store
          </a>
        </div>
        <Divider />
        <div className='profile-feature-display-invitation-key'>
          My invitation key
          <Title level={1} style={{ textAlign: 'center' }}>
            {user.invitation_key !== null
              ? user.invitation_key!
              : <div className="dot-flashing"></div>
            }
          </Title>
        </div>
        {/* <Title level={3}>OR</Title>
        <div className='profile-feature-display-qrcode'>
          {inviteQrCode !== ''
            ? <img src={inviteQrCode} alt="QRCode" />
            : <div className="dot-flashing"></div>
          }
        </div> */}
        <Title level={5}>
          {user.name !== null
            ? user.name!
            : <div className="dot-flashing"></div>
          }
        </Title>
        <Text>Join my GigCT network and earn 10 GIGCT token</Text>

      </div>

    </div>
  );
}

export default ProfileByIdFeature;
