import React from "react";

export default function Accordion({ Title = true }) {
  return (
    <>
      <section className={`container accordions ${Title ? "ptb-100" : ""}`}>
        {Title ? (
          <>
            <div className="row">
              <div className="col-lg-8 col-md-9">
                <div className="section-heading mb-5 ml-3">
                  <h3>Why should Gig Workers unite together?</h3>
                  <p className="lead">
                    Rideshare platforms do not care much about drivers. Drivers are often overworked and underpaid. Thus,
                    <span className='color-secondary font-weight-bold'> drivers need to get united and  fight for their economic right. </span>
                  </p>
                </div>
              </div>

            </div>
          </>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-lg-6">
            <div id="accordion-1" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-1"
                  aria-expanded="false"
                  aria-controls="collapse-1-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    What are GIGCT tokens?
                  </h6>
                </div>
                <div
                  id="collapse-1-1"
                  className="collapse"
                  aria-labelledby="heading-1-1"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                     If you know about DODGE, SHIBA INU (SHIB) or POLYGON (MATIC), then you can think GIGCT as another such token. To be more technical, GIGCT is ERC-20 token built on top of ETHEREUM blockchain. 
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-2"
                  aria-expanded="false"
                  aria-controls="collapse-1-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    Can I buy GIGCT token in crypto exchanges?
                  </h6>
                </div>
                <div
                  id="collapse-1-2"
                  className="collapse"
                  aria-labelledby="heading-1-2"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                    As of now, the answer is NO. We will never directly sell GIGCT tokens to crypto exchanges. However, drivers will eventually be able to sell the tokens they have earned through Coinbase exchange.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-3"
                  aria-expanded="false"
                  aria-controls="collapse-1-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3 color-secondary"></span> How can I start earning GIGCT tokens?
                  </h6>
                </div>
                <div
                  id="collapse-1-3"
                  className="collapse"
                  aria-labelledby="heading-1-3"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                    Download app, register and Click start button. When your car is moving, you will get 0.2 token every two minutes. You can earn GIGCT tokens when you scan your grocery or any expense receipts
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div id="accordion-2" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-1"
                  aria-expanded="false"
                  aria-controls="collapse-2-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    Do GIGCT tokens have any real value?
                  </h6>
                </div>
                <div
                  id="collapse-2-1"
                  className="collapse"
                  aria-labelledby="heading-2-1"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      To be honest, there is no current value because our Gig workers community has few members. Once our membership increases, 
                      the value of GIGCT tokens will go up. Think of early value of Ethereum (ETH) tokens. The value of ETH token when it launched in 2014 was $0.31 per coin and currently it is valued more than $1500 per token.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-2"
                  aria-expanded="false"
                  aria-controls="collapse-2-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span> How can I start earning GIGCT tokens?
                  </h6>
                </div>
                <div
                  id="collapse-2-2"
                  className="collapse"
                  aria-labelledby="heading-2-2"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                    Download app, register and click START button in the app. When your car is moving, you will get 0.2 GIGCT token every two minutes. You can also earn GIGCT tokens when you scan your grocery or any expense receipts.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-3"
                  aria-expanded="false"
                  aria-controls="collapse-2-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-widget mr-3 color-secondary"></span> How
                    do I see previous orders?
                  </h6>
                </div>
                <div
                  id="collapse-2-3"
                  className="collapse"
                  aria-labelledby="heading-2-3"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Efficiently supply B2B networks vis-a-vis best-of-breed
                      schemas. Dramatically parallel task reliable technology
                      with cross functional core competencies.
                      Phosfluorescently.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>

          </div>

        </div>
        {/* <div className="mt-5 text-center">
           <p className="mb-4">
             If you have any questions please 
             <a href="#/" className="color-secondary">
               {" "}
               Contact us{" "}
             </a>
           </p>
         </div> */}
      </section>
    </>
  );
}
