import React, { useEffect, useState } from 'react';
import { Typography, Card, Input, Row, Col, Button, Form } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './otp.less';
import logo from '../../../assets/images/logo-lg.png';
import FooterLink from '../../../components/common/footer-link';
import { useAppDispatch } from '../../../stores/store';
import { login } from '../../../stores/auth/login.slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { verifyOtp } from '../../../stores/auth/verify-otp.slice';
import BlankLayoutComponent from '../../../components/layout/BlankLayoutComponent';

const { Title, Text } = Typography;

interface Props {
  languages: any
}

interface CustomState {
  mobile: string;
  id: string;
}

const OtpFeature: React.FC<Props> = ({ languages }) => {
  const meta = {
    title: languages.head.title_verify_login,
    description: '',
    keyword: '',
  };
  const location = useLocation();
  const state = location.state as CustomState;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [id, setId] = useState<string>(state.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);

  const loginData = {
    mobile: state.mobile,
  };

  useEffect(() => {
    if (localStorage.getItem('authGcUser')) {
      return navigate('/dashboard', { replace: true });
    }
    setId(state.id);
  }, []);

  const _submitLogin = (mobile: String) => {
    setMessage('');
    if (mobile !== null) {
      setIsLoading(true);
      const loginData = {
        phone: state.mobile,
      };
      dispatch(login(loginData)).then(unwrapResult).then(res => {
        if (res.success) {
          setId(res.data.user._id);
          console.log(`user_id: ${id}`)
          setIsLoading(false);

        }
      })
        .catch(rejected => {
          setIsLoading(false);
          console.log({ rejected });
        });
    }
  }

  const onFinish = (values: any) => {
    setIsOtpLoading(!isOtpLoading);
    const verifyOtpData = {
      user_id: state.id,
      otp: values.otp.toString()
    };
    dispatch(verifyOtp(verifyOtpData))
      .then(unwrapResult)
      .then(res => {
        setIsOtpLoading(!isLoading);
        if (res.success) {
          console.log({ res });
          return navigate('/dashboard',
            {
              state: { id: res.user.id, name: res.user.name },
              replace: false
            });
        }
      })
      .catch(rejected => {
        setIsOtpLoading(false);
        setSuccess(rejected.data.success);
        setMessage(rejected.data.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const preventDecimal = (e: any) => {
    if (e.key === '.') { e.preventDefault(); }
  }

  return (
    <BlankLayoutComponent languages={languages} meta={meta} displayNavbar={false}>
      <div className='login-feature'>
        <div className='login-feature-logo'>
          <img src={logo} alt="GigCity" />
        </div>

        <Card bordered={false} className='login-feature-card'>
          <Title level={3} className="card-title">Verivy Login</Title>
          <Form
            layout="vertical"
            className="gig-login-form"
            onFinish={onFinish}
            // onFinishFailed={}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              name="otp"
              label="OTP"
              rules={[
                {
                  required: true,
                  message: 'Please enter your otp.',
                },
              ]}

            >
              <Input
                type="number"
                placeholder="Enter an otp"
                size="large"
                className="mobile-field"
                step="1"
                maxLength={6}
                onKeyDown={(event) => preventDecimal(event)}
              />
            </Form.Item>

            <Form.Item>
              <Row>
                <Col span={6}>{isLoading ? 'Wait...' : <Button type="text" onClick={() => _submitLogin(state.mobile)}>RESEND</Button>}</Col>
                <Col span={8} offset={8}>
                  <Form.Item>
                    <Button size="large" shape='round' loading={isOtpLoading} type="primary" htmlType="submit" disabled={isLoading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              {message !== '' ? <Text type='danger'>{message}</Text> : ''}
            </Form.Item>
          </Form>
        </Card>

        < FooterLink />
      </div >
    </BlankLayoutComponent>

  );
}

export default OtpFeature;