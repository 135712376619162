import React, { useEffect, useState } from 'react';
import { Typography, Space, Row, Col, Button, Modal, Divider, Alert, Form, Input } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import FooterLink from '../../../components/common/footer-link';
import AuthLayoutComponent from '../../../components/layout/AuthLayoutComponent';
import './dashboard.less';
// import useUserProfile from '../../../hooks/user-profile.hooks';
import { useWeb3React } from '@web3-react/core';
import { connectors } from "../../../web3/connector";
import { truncateAddress } from "../../../utils/utils";
import { RootState, useAppDispatch } from '../../../stores/store';
import { fetchUserProfile, linkUserAccount } from '../../../stores/users/users.slice';
import { becomeUnionMember, verifyUnionMemberOtp } from "../../../stores/union/union.slice";

import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

const { Text, Title, } = Typography;
interface Props {
  languages: any
}
interface CustomState {
  id: string;
  name: string;
  email: string;
  phone: string;
}
const DashboardFeature: React.FC<Props> = ({ languages }) => {
  const meta = {
    title: languages.head.title_dashboard,
    description: '',
    keyword: '',
  };

  // const param = useParams();
  const {
    // library,
    // chainId,
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();

  const location = useLocation();
  const dispatch = useAppDispatch();
  const state = location.state as CustomState;
  const [id, setId] = useState<string>();
  const [name, setName] = useState<string>();
  const [accountType, setAccountType] = useState<string>('none');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAccountLinked, setIsAccountLinked] = useState(false);
  const userProfile = useSelector((state: RootState) => state.users);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [otpMessage, setOtpMessage] = useState('');

  const [profile, setProfile] = useState({
    _id: null,
    name: null,
    email: null,
    phone: null,
    invitation_code: null,
    invitation_key: null,
    account_address: null,
    account_address_wallet_type: null,
  });
  const [unionMemberDetail, setUnionMemberDetail] = useState({
    _id: null,
    createed_at: null,
    email: null,
    member_id: null,
    name: null,
    phone: null,
    updated_at: null,
    user_id: null,
    verified: null
  });
  const [displayJoinTheMemberButton, setDisplayJoinTheMemberButton] = useState(false);
  // const { profile } = useUserProfile(id!);

  // const userDetail = JSON.parse(localStorage.getItem('authGcUser') || '{}');
  useEffect(() => {
    if (state !== null) {
      // console.log({ state });
      setId(state.id);
      setName(state.name);
      if (id) {
        dispatch(fetchUserProfile(id))
          .then(unwrapResult)
          .then(res => {
            if (res.success) {
              console.log('User selectd wallet:', res.data.profile.account_address_wallet_type);
              setProfile({
                account_address: res.data.profile.account_address,
                account_address_wallet_type: res.data.profile.account_address_wallet_type,
                invitation_code: res.data.profile.invitation_code,
                invitation_key: res.data.profile.invitation_key,
                name: res.data.profile.name,
                phone: res.data.profile.country_code + res.data.profile.phone,
                email: res.data.profile.email,
                _id: res.data.profile._id,
              });
              if (res.data.profile.account_address_wallet_type !== 'none') {
                setIsAccountLinked(true)
                setAccountType(profile.account_address_wallet_type!);
              }

              if (res.data.unionMemberDetail !== null) {
                setUnionMemberDetail({
                  _id: res.data.unionMemberDetail._id,
                  createed_at: res.data.unionMemberDetail.createed_at,
                  email: res.data.unionMemberDetail.email,
                  member_id: res.data.unionMemberDetail.member_id,
                  name: res.data.unionMemberDetail.name,
                  phone: res.data.unionMemberDetail.phone,
                  updated_at: res.data.unionMemberDetail.updated_at,
                  user_id: res.data.unionMemberDetail.user_id,
                  verified: res.data.unionMemberDetail.verified
                })
              } else {
                setDisplayJoinTheMemberButton(true);
              }
            }

            if (accountType === 'injected') {
              activate(connectors[accountType]);
            } else if (accountType === 'coinbaseWallet') {
              activate(connectors.coinbaseWallet);
            }
          })
          .catch(rejected => {
            console.log("error", { rejected });
            window.location.reload(); // FIXME: Need to refactor this react anti pattern
          });
      }
    }
  }, [id, isAccountLinked, active]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const setProvider = (type: any) => {
    window.localStorage.setItem("provider", type);
  };

  const refreshState = () => {
    window.localStorage.setItem("provider", 'undefined');
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };

  // console.log({ isAccountLinked }, { accountType });

  const linkMyAccount = () => {
    if (profile.account_address_wallet_type === 'none') {
      const provider = window.localStorage.getItem("provider");
      const linkAccount = {
        account_address: account!,
        account_address_wallet_type: provider!
      };
      dispatch(linkUserAccount(linkAccount))
        .then(unwrapResult)
        .then(res => {
          if (res.success) {
            setIsAccountLinked(true)
          }
        })
        .catch(rejected => {
          console.log(rejected);
        });
    }
  }

  const joinTheUnion = () => {
    const formData = {
      user_id: profile._id!,
      name: profile.name!,
      email: profile.email!,
      phone: profile.phone!,
    }
    console.log('Join the Union', { formData });
    setIsLoading(true);
    dispatch(becomeUnionMember(formData))
      .then(unwrapResult)
      .then(res => {
        if (res.success) {
          window.location.reload();
          setIsLoading(false);
        }
      })
      .catch(rejected => {
        setIsLoading(false);
        // console.log({ rejected });
        // alert(rejected.data.message);
        setMessage(rejected.data.message)
      });
  }

  const onVerifyMemberOtp = (values: any) => {
    setIsOtpLoading(!isOtpLoading);
    const verifyOtpData = {
      union_id: unionMemberDetail._id!,
      otp: values.otp.toString()
    };
    dispatch(verifyUnionMemberOtp(verifyOtpData))
      .then(unwrapResult)
      .then(res => {
        setIsOtpLoading(!isLoading);
        if (res.success) {
          window.location.reload()
        }
      })
      .catch(rejected => {
        setIsOtpLoading(false);
        setOtpMessage(rejected.data.message);
      });
  };

  const preventDecimal = (e: any) => {
    if (e.key === '.') { e.preventDefault(); }
  }

  return (
    <AuthLayoutComponent languages={languages} meta={meta} isProtected={true}>
      <div className='dashboard-feature'>
        <div className='dashboard-feature-display'>
          {!active
            ? <Space direction="vertical" style={{ width: '100%', alignItems: 'center', }}>
              <Title level={3}>Link GigCT account  to your crypto wallet</Title>
              <Text>Once you link your cryptowallet, GIGCT token that you earned in the app will be</Text>
              <Text>regurlay transferred to your wallet</Text>

              <Button size='large' type='primary' onClick={showModal} shape="round">Connect Wallet</Button>
            </Space>
            : <>
              <Row>
                <Col xs={24} xl={21}>
                  <Title level={3}>Dashboard</Title>
                </Col>
                <Col xs={24} xl={3}>
                  <Button size='large' onClick={disconnect} shape="round">{languages.button.text_disconnect}</Button>
                </Col>
              </Row>
              <Divider />

              {!isAccountLinked
                ? <Button size='large' onClick={() => linkMyAccount()} shape="round">Link My Account Address</Button>
                : ''}
              <div>Connect witn:  {(() => {
                if (accountType === 'none') {
                  return <span>NOT Connected with any wallet address.</span>
                } else if (accountType === 'injected') {
                  return <span className="wallet-type metamask">Metamask Wallet</span>

                } else if (accountType === 'coinbaseWallet') {
                  return <span className="wallet-type coinbase">Coinbase Wallet</span>
                }
              })()}</div>
              <div>Profile Name: {name}</div>
              <div>Account: ${truncateAddress(account!)} &nbsp; {isAccountLinked ? <Text type='success'>LINKED</Text> : <Text type='danger'>NOT LINKED</Text>}</div>
            </>
          }

          <Divider />

          <div className='union-member'>
            <Title level={4}>Union Member</Title>
            {(() => {
              if (unionMemberDetail.member_id === null) {
                return <>
                  {message !== '' ? <Alert
                    message="Error Text"
                    description={message}
                    type="error"
                  /> : ''}
                  <br />
                  {displayJoinTheMemberButton
                    ? <Button
                      size='large'
                      type='primary'
                      loading={isLoading}
                      onClick={() => { joinTheUnion() }}
                      shape="round">
                      {languages.button.text_join_the_union}
                    </Button>
                    : ''}
                </>;
              }
              else {
                return <>
                  <Text>Member Id: <strong>{unionMemberDetail.member_id}</strong></Text>
                  &nbsp;
                  {!unionMemberDetail.verified
                    ? <>
                      <Form
                        layout="vertical"
                        className="gig-login-form"
                        onFinish={onVerifyMemberOtp}
                        // onFinishFailed={}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                      >
                        {otpMessage !== '' ? <Alert
                          message="Error Text"
                          description={otpMessage}
                          type="error"
                        /> : ''}
                        <br />
                        <Row>
                          <Col span={8}>
                            <Form.Item
                              name="otp"
                              label="Union Member OTP"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter your otp.',
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder="Enter an otp"
                                size="large"
                                className="mobile-field"
                                step="1"
                                maxLength={6}
                                onKeyDown={(event) => preventDecimal(event)}
                              />
                            </Form.Item>

                            <Form.Item>
                              <Button size="large" shape='round' loading={isOtpLoading} type="primary" htmlType="submit" disabled={isLoading}>
                                Submit
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </>
                    : <Text type='success'>Verified</Text>}

                </>
              }
            })()}
          </div>
        </div>
      </div>

      <Modal title="Connect Wallet" visible={isModalVisible} footer={null} width={250} className="connect-wallet" onCancel={handleCancel}>
        {profile.account_address_wallet_type === 'coinbaseWallet' || profile.account_address_wallet_type === 'none'
          ? <Button type="link" size='large' shape='round' className='connect-wallet-coinbase connect-wallet-button' block onClick={() => {
            activate(connectors.coinbaseWallet);
            setProvider("coinbaseWallet");
            handleCancel();
          }}>
            Coinbase
          </Button>
          : ''}

        {/* {profile.account_address_wallet_type === 'injected' || profile.account_address_wallet_type === 'none'
          ? <Button type="link" size='large' shape='round' className='connect-wallet-metamask connect-wallet-button' block onClick={() => {
            activate(connectors.injected);
            setProvider("injected");
            handleCancel();
          }}>
            Metamask
          </Button>
          : ''} */}
      </Modal>
    </AuthLayoutComponent >
  );
}

export default DashboardFeature;
