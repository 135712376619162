import React from 'react';
import Accordion from '../../components/common/faq/Accordion';
import FeatureImgTwo from '../../components/features/FeatureImgTwo';
import BlankLayoutComponent from '../../components/layout/BlankLayoutComponent';
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import TextMeLinkComponent from '../../components/TextMeLink';
import HeroEight from './HeroEight';

interface Props {
  languages: any
}
const HomeFeature: React.FC<Props> = ({ languages }) => {

  const meta = {
    title: languages.head.title_home,
    description: '',
    keyword: '',
  };
  return (
    <BlankLayoutComponent languages={languages} meta={meta} displayNavbar={true}>

      <TestimonialTwo languages={languages} />
      {/* <PetitionComponent bgColor="secondary-bg" light={false} /> */}

      <HeroEight languages={languages} />

      <Accordion Title={undefined} />
      {/* <Promo /> */}
      <FeatureImgTwo languages={languages} />


    </BlankLayoutComponent>

  );
}

export default HomeFeature;