import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, Modal, Typography } from 'antd';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { appRequest } from '../stores/apprequest/apprequest.slice';
import { useAppDispatch } from '../stores/store';

const { Title, Text } = Typography;

interface Props {
  languages: any;
}

const DownloadAppComponent: React.FC<Props> = ({ languages }) => {
  const [type, setType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [val, setVal] = useState(0);
  const dispatch = useAppDispatch();

  const onFinish = (values: any) => {
    if (values.email !== null) {
      setIsLoading(true);
      const requestData = {
        email: values.email,
        type: type,
      };
      dispatch(appRequest(requestData)).then(unwrapResult).then(res => {
        console.log({ res });
        if (res.success) {
          setIsLoading(false);
          setRequestSuccess(true);
        }
      })
        .catch(rejected => {
          setIsLoading(true);
          setRequestSuccess(rejected.data.success);
          setMessage(rejected.data.message);
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const showModal = (type: string) => {
    setIsModalVisible(true);
    setType(type);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="download-btn">
        <a href="https://play.google.com/store/apps/details?id=xyz.gigcity.gigcity&hl=en&gl=US" target="_blank" className="btn google-play-btn mr-3 solid-btn">
          <span className="ti-android"></span> Google Play
        </a>
        <a href="https://apps.apple.com/us/app/gigct/id1624812250" target="_blank" className="btn app-store-btn solid-btn">
          <span className="ti-apple"></span> App Store
        </a>
      </div>

      <Modal title="Download the app" visible={isModalVisible} footer={null} width={500} onCancel={handleCancel}>
        {requestSuccess
          ? <><h3>Thank you!</h3><h4>We will get with you soon.</h4></>
          : <><h4>Submit your email and we will send you access link of the GigCT app. </h4>
            <Form
              layout="vertical"
              className="gig-login-form"
              onFinish={onFinish}
              // onFinishFailed={}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email.',
                  },
                ]}

              >
                <Input
                  type="text"
                  placeholder="Enter an email"
                  size="large"
                  className="mobile-field"
                  step="1"
                />
              </Form.Item>

              <Button
                loading={isLoading}
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                className="center-btn"
                block
              >
                {languages.button.text_submit}
              </Button>

              {message !== '' ? <Text type='danger'>{message}</Text> : ''}
            </Form></>}

      </Modal>
    </>
  );
}

export default DownloadAppComponent;