import React from "react";
import OwlCarousel from "react-owl-carousel";
import TextMeLinkComponent from "../TextMeLink";

interface Props {
  languages: any;
  hasBg?: boolean;
}

const TestimonialTwo: React.FC<Props> = ({ hasBg = false, languages }) => {
  const options = {
    loop: false,
    margin: 30,
    items: 1,
    nav: true,
    dots: false,
    responsiveClass: true,
    autoplay: false,
    autoplayHoverPause: true,
    lazyLoad: true,
  };
  return (
    <>
      <section
        className={`testimonial-section ptb-100 ${hasBg ? "gray-light-bg " : ""
          }`}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="section-heading mb-5">
                {/* {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Our Trusted Clients
                  </span>
                ) : (
                  " "
                )} */}
                <h2 style={{ marginTop: '50px' }} >What rideshare drivers say about GIGCT</h2>
                <p className="lead">
                  <span className='color-secondary font-weight-bold'> GigCT platform allows members to earn GIGCT crypto tokens while driving </span> for rideshare, food delivery or amazon flex.
                  Gigworkers can
                  scan grocery or any expense receipts and earn crypto tokens.
                </p>

                <div className="client-section-wrap d-flex flex-row align-items-center">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <img
                        src="assets/img/uber-icon.png"
                        width="85"
                        alt="client"
                        className="img-fluid"
                      />
                    </li>
                    <li className="list-inline-item">
                      <img
                        src="assets/img/lyft-icon.png"
                        width="85"
                        alt="client"
                        className="img-fluid"
                      />
                    </li>
                    <li className="list-inline-item">
                      <img
                        src="assets/img/doordash-icon.png"
                        width="85"
                        alt="client"
                        className="img-fluid"
                      />
                    </li>
                    <li className="list-inline-item">
                      <img
                        src="assets/img/flex-icon.png"
                        width="85"
                        alt="client"
                        className="img-fluid"
                      />
                    </li>
                  </ul>
                </div>

                <TextMeLinkComponent languages={languages} />
              </div>
            </div>
            <div className="col-md-5">
              <OwlCarousel
                className="owl-carousel owl-theme client-testimonial arrow-indicator"
                {...options}
              >
                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="assets/img/ashok.png"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Ashok Gurung</h5>
                        <span> Uber & Lyft Driver</span>
                        <br />
                        <p>New York</p>
                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="assets/img/quote.svg"
                          alt="quote"
                          className="img-fluid"
                        />
                        I was among the first few users of the app. The app has improved a lot. Keep working guys.
                        We need to get united and fight for our rights.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="testimonial-quote-wrap">
                    <div className="media author-info mb-3">
                      <div className="author-img mr-3">
                        <img
                          src="assets/img/abhi.jpg"
                          alt="client"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Abhi Shrestha</h5>
                        <span>Lyft Driver</span>
                        <br />
                        <p>Chicago</p>

                      </div>
                    </div>
                    <div className="client-say">
                      <p>
                        <img
                          src="assets/img/quote.svg"
                          alt="quote"
                          className="img-fluid"
                        />
                        I have been driving for lyft for many years and never felt that we have power as drivers.
                        I am excited that we can finally get united. I have been earning GIGCT tokens for last few months. App is pretty cool.
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TestimonialTwo;