import React from 'react';
import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet';
import { Button } from 'antd';
import Logo from '../assets/images/logo-lg.png';

interface Props {
  languages: any
}

const PageNotFound: React.FC<Props> = ({ languages }) => {

  const navigate = useNavigate();

  return (
    <div className="page-not-found">
      <Helmet>
        <title>{languages.head.title_not_found}</title>
      </Helmet>
      <img src={Logo} alt="logo" className="logo" />
      {' '}
      <h1>{languages.heading.text_not_found}</h1>
      <div className="content">
        <Button onClick={() => navigate('/')}>Go Back</Button>
      </div>
    </div>
  );
};

export default PageNotFound;