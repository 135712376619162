import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './AuthLayoutComponent.less';

import FooterLink from '../common/footer-link';
import AuthHeader from '../common/auth-header';


type Meta = {
  title: string,
  description?: string,
  keyword?: string,
}

interface Props {
  children: any,
  languages: any,
  meta: Meta
  isProtected: boolean;

}
const { Content } = Layout;

const AuthLayoutComponent: React.FC<Props> = ({ children, languages, meta, isProtected }) => {
  const [collapsed, setStateCollapsed] = useState(false);
  const [activeAuthClass, setactiveAuthClass] = useState('');
  const toggle = () => {
    setStateCollapsed(!collapsed);
  };
  const [usersName, setUsersName] = useState<String>('');
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('authGcUser')) {
      navigate('/', { replace: true });
    } else {
      const user = JSON.parse(localStorage.getItem('authGcUser') || '{}');
      setUsersName(user.name);
      setactiveAuthClass('primaryBg');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keyword" content={meta.keyword} />
      </Helmet>
      <div id="auth-layout-compment" className={activeAuthClass}>
        <div className='container'>
          <AuthHeader languages={languages} userName={usersName} />

          <Content>
            {children}
          </Content>

          <FooterLink />
        </div>
      </div>
    </>
  );
};

export default AuthLayoutComponent;