import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { Fragment } from 'react';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";
import './assets/less/app.less';
import PageNotFound from './features/404';
import { Languages } from './languages/en';
import AuthMiddleware from './routes/middleware/auth.middleware';
import { routes, userRoutes } from './routes/routes';

window.Buffer = window.Buffer || require("buffer").Buffer;

type routeType = {
  path: string;
  component: any;
}

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Fragment>
          <Routes>
            {routes.map((route: routeType, index: number) => (
              <Route key={index} path={route.path} element={<route.component languages={Languages} />} />
            ))}

            <Route element={<AuthMiddleware isAuthProtected={true} />}>
              {userRoutes.map((route: routeType, index: number) => (
                <Route key={index} path={route.path} element={<route.component
                  languages={Languages}
                  element={route.component}
                />} />
              ))}
            </Route>

            <Route path='not-found' element={<PageNotFound languages={Languages} />} />
          
          </Routes>
        </Fragment>
      </BrowserRouter>

    </div>
  );
}

export default App;
