import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

export const fetchServices = createAsyncThunk(
  'service/fetchStatus',
  async (_, thunkAPI) => {
    try {
      const response = await apiClient.get('/service');
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialServiceState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
  services: {},
};

const serviceSlice = createSlice({
  name: 'services',
  initialState: initialServiceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.services = action.payload.data;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      });
  }
});

export default serviceSlice.reducer;
