import React, { useState } from "react";
import { Button, Form, Input, Select, Row, Col, Typography, Alert } from 'antd';
import { useAppDispatch } from '../stores/store';
import './text-me-link.less';
import { appTextMeLink } from "../stores/apprequest/apprequest.slice";
import { unwrapResult } from "@reduxjs/toolkit";
const { Title, Text } = Typography;
const { Option } = Select;

interface Props {
  languages: any;
}

const TextMeLinkComponent: React.FC<Props> = ({ languages }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const preventDecimal = (e: any) => {
    if (e.key === '.') { e.preventDefault(); }
  }

  const onFinish = (values: any) => {

    if (values.mobile !== null) {
      setIsLoading(true);
      const appTextMeLinkData = {
        phone: values.country_code + values.mobile.toString()
      };
      dispatch(appTextMeLink(appTextMeLinkData)).then(unwrapResult).then(res => {
        console.log({ res });
        if (res.success) {
          setIsLoading(false)
          setRequestSuccess(true);
          setMessage(res.message);

        }
      })
        .catch(rejected => {
          setIsLoading(true);
          setRequestSuccess(rejected.data.success);
          setMessage(rejected.data.message);
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const countryCodeSelector = (
    <Form.Item name="country_code" noStyle rules={[
      {
        required: true,
        message: 'Please select a country code.',
      },
    ]}>
      <Select style={{ width: 120 }} placeholder="Code">
        <Option key={1} value="+1">+1</Option>
      </Select>
    </Form.Item>
  );

  return <>
    <div>
      {message !== '' ? <div className="alert-box"><Alert
        message={requestSuccess ? "Success" : "Error"}
        description={message}
        type={requestSuccess ? "success" : "error"}
      /></div> : ''}
      <div className="text-me-link">
        {!requestSuccess ?
          <Form
            layout="inline"
            className="text-me-link-form"
            onFinish={onFinish}
            // onFinishFailed={}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row>
              <Col>
                <Form.Item
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your mobile number.',
                    },
                  ]}

                >
                  <Input
                    addonBefore={countryCodeSelector}
                    type="tel"
                    placeholder="Enter your mobile number"
                    size="large"
                    className="mobile-field"
                    step="1"
                    onKeyDown={(event) => preventDecimal(event)}
                    inputMode='numeric'
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  loading={isLoading}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="center-btn"
                  block
                >
                  {languages.button.text_send_me_link}
                </Button>
              </Col>
            </Row>
          </Form>
          : ''}
      </div>
    </div>
  </>;
}

export default TextMeLinkComponent;