import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

interface otpData {
  user_id?: string;
  otp: string;
}

export const verifyOtp = createAsyncThunk(
  'verifyOtp/verifyOtpStatus',
  async (formData: otpData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/user/verify-otp', formData);
      if (response.status === 201) {
        let objUserAuth = {
          id: response.data.user.id,
          name: response.data.user.name,
          token: response.data.token,
        };
        let authGcUser = localStorage.getItem('authGcUser');
        if (authGcUser !== null) localStorage.removeItem('authGcUser');
        localStorage.setItem('authGcUser', JSON.stringify(objUserAuth));
        apiClient.interceptors.request.use(config => {
          config.headers!.Authorization = response.data.token ? `Bearer ${response.data.token}` : '';
        });
        return response.data;
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialOtpState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
  user: {},
  token: null,
  verifyOtp: false,
};

const verifyOtpSlice = createSlice({
  name: 'verify-top',
  initialState: initialOtpState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.verifyOtp = action.payload.success;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      });
  }
});

export default verifyOtpSlice.reducer;
