import React from 'react';
import { Typography } from 'antd';


import './about.less';
import FooterLink from '../../components/common/footer-link';
import BlankLayoutComponent from '../../components/layout/BlankLayoutComponent';
import PageHeader from '../../components/common/header/PageHeader';

const { Text, Title, } = Typography;

interface Props {
  languages: any
}

const AboutFeature: React.FC<Props> = ({ languages }) => {
  const meta = {
    title: 'About Us',
    description: '',
    keyword: '',
  };

  return (
    <BlankLayoutComponent languages={languages} meta={meta} displayNavbar={false}>
      <PageHeader HeaderTitle="About Us" Parent="Page" PageTitle="About Us" />
      <div className='about-feature container'>
        <div className='about-feature-display'>

          <Title level={1}>About Us</Title>
          <Text>About up content goes here...</Text>

        </div>
        <FooterLink />
      </div>
    </BlankLayoutComponent>
  );
}

export default AboutFeature;
