import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';
import { UserOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import QRCode from 'qrcode';

import './navbar.less';

interface Props {
  darkBg: boolean;
  classOption: string;
  languages: any;
}

const Navbar: React.FC<Props> = ({ darkBg, classOption, languages }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [gigCtQrCode, setGigCtQrCode] = useState('');

  const generateQrCode = (url: string) => {
    QRCode.toDataURL(url).then((setGigCtQrCode));
  }

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    // setHeaderTop(stickyheader!.offsetTop);
    generateQrCode('http://www.gigcity.xyz/');
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const scanAndOpen = () => {
    Modal.info({
      title: 'Scan to open gigcity.xyz',
      content: (
        <div>
          <img src={gigCtQrCode} alt="QRCode" width={250} />
        </div>
      ),
    });
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav white-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src="assets/img/gigct-white.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/gigct-blue.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              id="navbarToggler"
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">

                <li className="nav-item">
                  <Link to="/auth/login">
                    <UserOutlined /> {languages.button.text_login}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="#" onClick={scanAndOpen}>
                    <ShareAltOutlined /> Share
                  </Link>
                </li>
              </ul>

            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
