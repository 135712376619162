import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Navbar from '../common/header/Navbar';
import Footer from '../common/footer/Footer';

type Meta = {
  title: string;
  description?: string;
  keyword?: string;
}

interface Props {
  children: any,
  languages: any,
  meta: Meta
  displayNavbar: boolean;
  displayFooter?: boolean;
}

const BlankLayoutComponent: React.FC<Props> = ({ children, languages, meta, displayNavbar, displayFooter = true }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('authGcUser')) {
      navigate('/dashboard', { replace: true });
    }
  }, []);
  return (
    <>
      {displayNavbar
        ?
        <Navbar darkBg={false} classOption="custom-header" languages={languages} /> : ''
      }
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keyword" content={meta.keyword} />
      </Helmet>
      <div className="blank-layout">
        {children}
      </div>
      {displayFooter ? <Footer space={'space'} /> : ''}

    </>

  );
};

export default BlankLayoutComponent;