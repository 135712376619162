import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

interface memberData {
  user_id?: string;
  name: string;
  email: string;
  phone: string;
}

export const becomeUnionMember = createAsyncThunk(
  'union/beMemberStatus',
  async (formData: memberData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/union-member', formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

interface otpData {
  union_id: string;
  otp: string;
}

export const verifyUnionMemberOtp = createAsyncThunk(
  'verifyUnionMemberOtp/verifyUnionMemberOtpStatus',
  async (formData: otpData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/union-member/verify-otp', formData);
      if (response.status === 201) {
        return response.data;
      }
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialUnionState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
  union: {
    _id: null,
    name: null,
    verified: null,
  },
};

const unionSlice = createSlice({
  name: 'union',
  initialState: initialUnionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(becomeUnionMember.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(becomeUnionMember.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.union = action.payload.data;
      })
      .addCase(becomeUnionMember.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      })
      .addCase(verifyUnionMemberOtp.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(verifyUnionMemberOtp.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.union = action.payload.data;
      })
      .addCase(verifyUnionMemberOtp.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      });
  }
});

export default unionSlice.reducer;
