export const Languages = {
  app: {
    title: 'Gig City',
    meta_description: 'Gig City',
    organization: 'ChainIntelAi',
    info_mail: 'info@ceetleheroes.org',
    address: 'Jhatapol - 11, Lalitpur, Nepal.',
    copyright: new Date().getFullYear() + ' © GigCity.com, Develop by ChainIntelAi.'
  },
  head: {
    title_home: 'Home | Gig City',
    title_login: 'Login | Gig City',
    title_business_login: 'Business Login | Gig City',
    title_register: 'Register | Gig City',
    title_forgot_password: 'Forgot password | Gig City',
    title_verify_email: 'Verify your email | Gig City',
    title_verify_login: 'Verify your login | Gig City',
    title_user_profile: 'User Profile | Gig City',
    title_dashboard: 'Dashboard | Gig City',
    title_analytics: 'Analytics | Gig City',
    title_setting: 'Setting | Gig City',
    title_users: 'Users | Gig City',
    title_create_user: 'Create New User | Gig City',
    title_not_found: 'Page Not Found | Gig City',
    title_group_circle: 'Communities Circles | Gig City',
    title_about: 'About Us | Gig City',
    title_Features: 'Features | Gig City',
    title_News: 'News | Gig City',
    title_faqs: 'FAQ\'s | Gig City',
    title_whitepaper: 'Whitepaper | Gig City',
    title_token_sales: 'Token Sales | Gig City',
    title_privacy_policy: 'Privacy Policy | Gig City',
    title_terms_condition: 'Terms & Conditions | Gig City',
    title_token: 'Token | Gig City',
    title_roadmaps: 'Roadmaps | Gig City',
    title_explore: 'Explore | Gig City',
    title_stories: 'Stories | Gig City',
    title_blogs: 'Blogs | Gig City',
    title_driver_activities: 'Earning Activities',
    title_profile: 'profile',
    title_otp: 'OTP',
    title_success: 'Success',
    title_gig_locations: 'Gig Locations',
    title_join_now: 'Join Now | Gig City',
    title_guest_request: 'Guest Request | Gig City',
    title_guest_invitation_request: 'Invitation Key Request | Gig City',
  },
  heading: {
    text_welcome: 'Welcome',
    text_welcome_back: 'Welcome Back',
    text_login: 'Login',
    text_verify_login: 'Login',
    text_register: 'Register',
    text_user_profile: 'Profile',
    text_dashboard: 'Dashboard',
    text_setting: 'Setting',
    text_information: 'Information',
    text_change_password: 'Change Password',
    text_forgot_password: 'Forgot Password',
    text_verify_email: 'Verify Your Email',
    text_users: 'Users',
    text_create_user: 'Create New User',
    text_not_found: 'Page Not Found',
    text_about: 'About Us',
    text_Features: 'Features',
    text_News: 'News',
    text_faqs: 'FAQ\'s',
    text_whitepaper: 'Whitepaper',
    text_token_sales: 'Token Sales',
    text_privacy_policy: 'Privacy Policy',
    text_terms_condition: 'Terms & Conditions',
    text_token: 'Token',
    text_roadmaps: 'Roadmaps',
    text_driver_activities: 'Earning Activities',
    text_profile: 'Profile',
    text_otp: 'OTP',
    text_success: 'Success',
    text_verify_account_access: 'Verify account access.',
    text_gig_locations: 'Gig Locations',
    text_gig_locations_initilize: 'Initilize Gig Locations',
    text_join_now: 'Join Now',
    title_guest_request: 'Guest Request',
    title_guest_invitation_request: 'Request An Invitation Key',
  },
  button: {
    text_submit: 'Submit',
    text_update: 'Update',
    text_post: 'Post',
    text_add: 'Add',
    text_edit: 'Edit',
    text_delete: 'Delete',
    text_login: 'Login',
    text_register: 'Register',
    text_verify: 'Verify',
    text_confirm: 'Confirm',
    text_filter: 'Filter It',
    text_connect_with_metamask: 'Connect With Metamask',
    text_get_magic_link: 'Get a magic link',
    text_join_now: 'Join Now',
    text_join_the_union: 'Join The Union',
    text_disconnect: 'Disconnect',
    text_send_request: 'Request',
    text_send_me_link: 'Text Me Link',
  },
  link: {
    text_have_an_account: 'Already have an account',
    text_create_an_account: 'Create an account',
    text_forgot_password: 'Forgot Password',
    text_get_invtation_key: 'Get an invitation key',
  },
  general: {
    text_verify_email: 'We have sent you verification email',
    text_check_email: 'Please check it.',
    text_4_digit_code: 'Please enter 4 digit code',
    text_member: 'Become a member and earn crypto City',
    text_ride_share_service: 'Which Ride share service you drive?',
    text_upload_profile_screen: 'Upload Profile Screenshot',
    text_upload_page_screen: 'Upload Driver requirements page Screenshot',
    text_verify_active_driver: 'Verify that you are active driver',
    text_login_success: 'Your access is sent to your email. Please check!',
    text_invite: 'Invite people to network',
    text_member_on_network: 'Member on Network',
    text_invite_qrcode: 'Invite with QRCode',
    text_enter_otp: 'Enter your 6 digit OTP.',
    text_have_account: 'Already a member?',
    text_have_no_account: 'New to GigCT ?',
  },
};