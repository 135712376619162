import React from 'react';
import { Typography } from 'antd';

import './about.less';
import FooterLink from '../../components/common/footer-link';

const { Text, Title, } = Typography;

const AboutDeviceFeature = () => {

  return (
    <div className='about-device-feature'>
      <div className='about-device-feature-display'>
        <Title level={1}>About Us</Title>
        <Text>About up content goes here...</Text>
      </div>

      <FooterLink />
    </div>


  );
}

export default AboutDeviceFeature;
