import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

interface guestRequestData {
  name: string;
  email: string;
  phone: string;
  request_type: string;
  message: string;
}

export const sendGuestRequest = createAsyncThunk(
  'sendGeustRequest/sendGeustRequestStatus',
  async (formData: guestRequestData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/guest-request', formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialGuestRequestState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
  data: {},
};

const guestRequestSlice = createSlice({
  name: 'guestRequest',
  initialState: initialGuestRequestState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendGuestRequest.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(sendGuestRequest.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.data = action.payload.data;
      })
      .addCase(sendGuestRequest.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      });
  }
});

export default guestRequestSlice.reducer;
