import { Navigate, RouteProps, Outlet } from 'react-router-dom';

export type ProtectedRouteProps = {
  isAuthProtected: boolean;
} & RouteProps;


const AuthMiddleware = ({ isAuthProtected, ...routeProps }: ProtectedRouteProps) => {
  const isAuth = useAuth(isAuthProtected);
  // console.log(isAuth);
  return isAuth ? <Outlet context={routeProps} /> : <Navigate to="/" />;
};

const useAuth = (isAuthProtected: boolean) => {
  if (isAuthProtected && !localStorage.getItem('authGcUser')) {
    return false;
  }
  return true;
}

export default AuthMiddleware;