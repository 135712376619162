import { Link } from 'react-router-dom';
import "./footer-link.less";
const FooterLink = () => {
  return (
    <footer className='footer-menu'>
      <nav className='footer-menu-navitation'>
        <ul className='footer-menu-navitation-list'>
          <li><Link to="/">Home</Link></li>
          {/* <li><Link to="/about">About</Link></li> */}
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
        </ul>
      </nav>
    </footer>
  );
}

export default FooterLink;