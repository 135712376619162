import axios from 'axios';

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 36000,
  // `headers` are custom headers to be sent
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'api_username': `${process.env.REACT_APP_API_USERNAME}`,
    'api_key': `${process.env.REACT_APP_API_KEY}`,
  },

  // `responseType` indicates the type of data that the server will respond with
  // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  responseType: 'json', // default
});

// Executed for every axios request
apiClient.interceptors.request.use(config => {
  //document.getElementById('loading').style.display = 'block';
  return config;
}, error => {
  return Promise.reject(error.response);
});

// Executed for every axios response
apiClient.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error.response);
});

const storageData = localStorage.getItem('authGcUser');
const user = storageData !== null ? JSON.parse(localStorage.getItem('authGcUser') || '{}') : storageData;
if (user) {
  // console.log("apiClient storage", { user });
  const access_token = user.token;
  apiClient.interceptors.request.use(config => {
    if (config.headers!.authorization === undefined) {
      config.headers!.authorization = `Bearer ${access_token}`;
    }
    return config;
  }, error => {
    console.log(error.response);
    return Promise.reject(error.response);
  });

  apiClient.interceptors.response.use(response => {
    return response;
  }, error => {
    console.log(error);
    return Promise.reject(error);
  });
};


export default apiClient;