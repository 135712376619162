import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../config/axios.config';

interface loginData {
  phone: string;
}

export const login = createAsyncThunk(
  'login/loginStatus',
  async (formData: loginData, thunkAPI: any) => {
    try {
      const response = await apiClient.post('/user/login-phone', formData);
      return response.data;
    } catch (e) {
      console.log('data', e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const initialLoginState = {
  loading: 'Not loaded',
  error: {},
  success: null,
  message: null,
  user: {
    _id: null,
    name: null,
  },
};

const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoginState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = 'loaded';
        state.success = action.payload.success;
        state.message = action.payload.message;
        state.user = action.payload.data.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = 'error';
        state.error = action.error;
      });
  }
});

export default loginSlice.reducer;
