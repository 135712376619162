import { InjectedConnector } from "@web3-react/injected-connector"; //METAMASK
import { WalletLinkConnector } from "@web3-react/walletlink-connector"; //COINBASE
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
});


const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.REACT_INFURA_KEY}`,
  appName: "SOMiT"
});

export const connectors = {
  injected: injected,
  coinbaseWallet: walletlink
};
