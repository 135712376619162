import {
  AboutDeviceFeature,
  AboutFeature,
  HomeFeature,
  LoginFeature,
  OtpFeature,
  PrivacyDeviceFeature,
  PrivacyFeatury,
  ProfileFeature,
  TermsDeviceFeature,
  TermsFeature,
  DashboardFeature,
  RegisterFeature,
  GuestRequestFeature,
  ProfileByIdFeature
} from '../features';

const routes = [
  { path: '/', component: HomeFeature },
  { path: '/:username', component: ProfileFeature },
  { path: '/privacy-policy', component: PrivacyFeatury },
  { path: '/terms-and-conditions', component: TermsFeature },
  { path: '/d/privacy-policy', component: PrivacyDeviceFeature },
  { path: '/d/terms-and-conditions', component: TermsDeviceFeature },
  { path: '/about', component: AboutFeature },
  { path: '/d/about', component: AboutDeviceFeature },
  { path: '/join-now', component: RegisterFeature },
  { path: '/auth/login', component: LoginFeature },
  { path: '/auth/otp', component: OtpFeature },
  { path: '/guest-request/:type', component: GuestRequestFeature },
  { path: '/user/:id', component: ProfileByIdFeature },

];

const userRoutes = [
  { path: '/dashboard', component: DashboardFeature },
];

export { routes, userRoutes };