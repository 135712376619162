
import { Typography } from 'antd';
import React from "react";
import { Link } from "react-router-dom";
import DownloadAppComponent from '../DownloadApp';
import DownloadApp from '../DownloadApp';

const { Title, Text } = Typography;
interface Props {
  languages: any;
}
const FeatureImgTwo: React.FC<Props> = ({ languages }) => {


  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>
                  Membership Benefits
                </h2>

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/rideshare.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      <span className='color-primary font-weight-bold'> Earn GIGCT tokens when you drive for rideshare or delivery </span>
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/scanreceipt.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">

                      <span className='color-primary font-weight-bold'> Earn GIGCT tokens when you scan your grocery or any expense receipts</span>

                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/invite.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      <span className='color-primary font-weight-bold'>Earn more GIGCT tokens by inviting and adding your gigworker friends in your group</span>
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/marketplace.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      <span className='color-primary font-weight-bold'>  Get various member only deals when you shop for car insurance, health insurance, gym membership and grocery purchases</span>

                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/GigCT-Merged-Screen.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <DownloadAppComponent languages={languages} />
        </div>
      </section>
    </>
  );
}

export default FeatureImgTwo;