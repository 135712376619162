import { Link } from "react-router-dom";
import DownloadAppComponent from "../../components/DownloadApp";
import useHandleFocus from "../../hooks/useHandleFocus.hook";

interface Props {
  languages: any;
}

const HeroEight: React.FC<Props> = ({ languages }) => {
  let { handleFocusFullNameBox } = useHandleFocus()
  return (
    <>
      <section className="hero-section pt-100 gray-light-bg ">
        {/* <div className="circles">
          <div className="point animated-point-1"></div>
          <div className="point animated-point-2"></div>
          <div className="point animated-point-3"></div>
          <div className="point animated-point-4"></div>
          <div className="point animated-point-5"></div>
          <div className="point animated-point-6"></div>

        </div> */}
        <div className="container" id="animation-area-1">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="hero-content section-title text-center text-lg-left my-5 mt-lg-0">
                <span className="text-uppercase color-secondary font-weight-bold">
                We empower gig workers to collectively bargain.
                  <br />
                  <br />
                </span>

                {/* <h3 className="font-weight-bold">
                 We are building 
                </h3>
                <h2 className="font-weight-bold">
                 GIGWORKERS union
                </h2> */}

                {/* <p className="lead">
                  Progressively fashion impactful synergy after covalent quality
                  vectors. Phosfluorescently administrate highly efficient
                  e-business without 24/7 partnerships.{" "}
                </p> */}
                <div style={{ marginBottom: '30px' }}>
                  {/* <SingleTestimonial /> */}
                </div>

                <div className='hero-content section-title text-center text-lg-left my-5 mt-lg-0 color-primary font-weight-bold'> Are you a Gig Worker?

                  {/* <Link to="/" className="button btn solid-btn  ml-4" onClick={(e) => {
                    handleFocusFullNameBox(e); if (window.innerWidth < 1000) {
                      document.getElementById('navbarToggler')?.click()
                    };
                  }}>
                    Join Us
                  </Link> */}
                  <Link to="/join-now" className="button btn solid-btn  ml-4">Join Us</Link>


                </div>

                

                <h3> <br /> Earn< span className="color-secondary"> GIGCT </span> crypto tokens</h3>
                <div style ={{marginTop: '50px'}}>

                <DownloadAppComponent languages={languages} />
                  </div>


      

                {/* <div className="action-btn mt-4">
                  <a href="#/" className="btn solid-btn">
                    Join Gigworkers Union
                  </a>
                </div> */}
                {/* <form action="#" method="post" className="subscribe-form">
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control input"
                      id="email"
                      name="email"
                      placeholder="youremail@domain.com"
                    />
                    <input
                      type="submit"
                      className="button btn solid-btn "
                      id="submit"
                      value="Join Now"
                    />
                  </div>
                </form> */}
                <br />


              </div>

            </div>
            <div className="col-9 col-md-7 col-lg-5 offset-xl-1 align-self-sm-end">
              <div className="hero-img position-relative">
                <div className="image-bg-positioning">
                  <img
                    src="assets/img/GigCT-DriveScreen.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="animation-item d-none d-md-block d-lg-block">
                  <div className="p-4 w-75 rounded-custom d-flex white-bg hero-animated-card-1">
                    <p className="gr-text-11 mb-0 text-mirage-2">
                      I was among the first few users of the app. The app has improved a lot. Keep working guys.
                      We need to get united and fight for our rights.
                    </p>
                    <div className="small-card-img ml-3">
                      <img
                        src="assets/img/ashok.png"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                  </div>
                  <div className="p-4 w-75 rounded-custom d-flex secondary-bg hero-animated-card-2">
                    <div className="small-card-img mr-3 text-white">
                      <img
                        src="assets/img/abhi.jpg"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                    <p className="gr-text-11 mb-0 text-white">
                      I have been earning GIGCT tokens for last few months. App is pretty cool.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


export default HeroEight;