import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServices } from '../stores/services/services.slice';
import { RootState } from '../stores/store';

const useService = () => {
  const dispatch = useDispatch();
  const services = useSelector((state: RootState) => state.services);

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  return services;
};

export default useService;
