import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux'

import serviceReducer from './services/services.slice';
import loginReducer from './auth/login.slice';
import registerReducer from './auth/register.slice';
import verifyOtpReducer from './auth/verify-otp.slice';
import userReducer from './users/users.slice';
import unionReducer from './union/union.slice';
import appRquestReducer from './apprequest/apprequest.slice';
import guestRequestReducer from './guest-request/guest-request.slice';

export const store = configureStore({
  reducer: {
    'services': serviceReducer,
    'login': loginReducer,
    'register': registerReducer,
    'verify-otp': verifyOtpReducer,
    'users': userReducer,
    'union': unionReducer,
    'appRquest': appRquestReducer,
    'guestRequest': guestRequestReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export default store;
